<template>
  <div>
    <vs-row vs-w="12">
      <GlobalFilters
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        :page="'clinics'"
        @updateFilters="updateFilters"
        :urlType="urlType"
      />
      <!-- Chart -->
      <vs-col vs-w="12">
        <vs-row
          v-if="allTotalCount"
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="right"
          vs-w="12"
          >Total count of days:
          {{ Number.parseFloat(allTotalCount).toFixed(0) }}
        </vs-row>
        <BarChart :chartData="chartData" :chartOptions="chartOptions" />
      </vs-col>
      <!-- <vs-col vs-w="12" class="mt-4">
        <div class="flex items-center quick__insights p-2">
          <feather-icon
            style="cursor: pointer"
            icon="InfoIcon"
            svgClasses="h-4 w-4"
            class="ml-1"
          />
          <span class="ml-3 text-sm">QUICK INSIGHT: <b>9.8 months</b> your average time for returning customers (<b>+2.7 months</b> compared to other Fresh Clinics)</span>
        </div>
      </vs-col> -->
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BarChart from "./charts/BarChart.vue";
import GlobalFilters from "./common/GlobalFilters";
import { filterCategories } from "./common/GlobalFilters/filterConstant.js";

export default {
  components: {
    BarChart,
    GlobalFilters,
  },
  props: {
    type: {
      type: String,
      default: "month",
    },
    selected: {
      type: Object,
    },
    chartDataProps: {
      type: Object,
    },
  },
  data() {
    return {
      categories: [filterCategories.ORGANIZATIONS, filterCategories.CLINICS],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "bottom",
            labels: {
              boxWidth: 12,
            },
          },
          title: {
            display: true,
            text: "",
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "NUMBER OF DAYS",
            },
            min: 0,
          },
          x: {
            title: {
              display: true,
              text: "",
            },
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10,
              },
              maxRotation: 90,
              minRotation: 90,
            },
          },
        },
      },
      urlType: "",
    };
  },
  computed: {
    allTotalCount() {
      let count = 0;
      const chartDataSets = this.chartData.datasets
        ? this.chartData.datasets[0]
        : null;
      if (chartDataSets && chartDataSets.data) {
        chartDataSets.data.forEach((val) => {
          count += val;
        });
      }

      return count;
    },
  },
  methods: {
    ...mapActions("analytics", ["fetchAnalyticsFilters"]),
    updateFilters(newVal) {
      this.$emit("updateFilters", newVal);
    }
  },
  watch: {
    chartDataProps: {
      handler(newVal) {
        this.chartData = newVal;
      },
      immediate: true,
    },
    chartData: {
      handler(newVal) {
        this.$emit("updateChartData", newVal);
      },
      deep: true,
    },
    type: {
      handler(newVal) {
        this.chartOptions.scales.x.title.text = newVal.toUpperCase();
      },
      immediate: true,
    },
  },
  async created() {
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.orgId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if (userType && userType.userType === "superAdmin") {
      this.urlType = "SuperAdmin";
    } else if (
      userType &&
      userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()
    ) {
      this.urlType = "Supplier";
    } else {
      this.urlType = "OrgOwner";
    }
  },
};
</script>

<style scoped>
.quick__insights {
  background: #d8effe;
  border: 1.07993px solid #c3e2f7;
  border-radius: 7px;
  color: #0098f8;
}
</style>