import { render, staticRenderFns } from "./Clinics.vue?vue&type=template&id=3cda0457&scoped=true&"
import script from "./Clinics.vue?vue&type=script&lang=js&"
export * from "./Clinics.vue?vue&type=script&lang=js&"
import style0 from "./Clinics.vue?vue&type=style&index=0&id=3cda0457&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cda0457",
  null
  
)

export default component.exports